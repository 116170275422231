import React, { useEffect, useRef, useState } from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
// import ReactPlayer from 'react-player';
import axios from 'axios';
import trillBoardLogo from '../Trillboard Logo.png';
import connectedImage from '../Group 1686554634.png';
import io from 'socket.io-client';
import { getCurrentBrowserFingerPrint } from '@rajesh896/broprint.js';
// import QRCode from 'react-qr-code';
import './ScreenConnected.css';
import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react';
import { QRCode } from 'react-qrcode-logo';
import NoSleep from 'nosleep.js';

// const spanStyle = {
//   padding: '20px',
//   background: '#efefef',
//   color: '#000000'
// }

// const divStyle = {
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
//   backgroundSize: 'cover',
//   height: '100vh',
//   backgroundPosition:'center'
// }

const ScreenConnected = ({ myId, socket, myFingerPrint }) => {
  console.log("myId Screen Connected", myId, socket);
  const [myImages, setmyImages] = useState([]);
  // const [jcheck,setjcheck]=useState(false);

  // const videoRef = useRef(null);
  // const [isPlaying, setIsPlaying] = useState(false);
  let adsData = useRef([]);
  const [myfilteredData, setmyfilteredData] = useState([]);
  const [myfilteredDataLength, setmyfilteredDataLength] = useState(0);
  const [myTime, setmyTime] = useState('');
  const [myfingerprint, setmyFingerPrint] = useState('');
  const [isData, setisData] = useState(false);
  const [myAds, setmyAds] = useState([]);
  const [currentAdIndex, setCurrentAdIndex] = useState(0);
  const [myDelay, setmyDelay] = useState(null);
  const myTimeRef = useRef(null);

  const videoRef = useRef(null); // Create a ref to the video element
  const noSleep = useRef(new NoSleep());


   useEffect(() => {
    const videoElement = videoRef.current;

    // Mute the video when the component mounts or currentAdIndex changes
    if (videoElement) {
      videoElement.muted = true;
    }

    const handlePlay = () => {
      if (videoElement) {
        videoElement.muted = false; // Unmute the video when it starts playing
      }
      noSleep.current.disable(); // Allow screen to sleep when video is playing
    };

    const handlePause = () => {
      noSleep.current.enable(); // Prevent screen from sleeping when video is paused
    };

    if (videoElement) {
      videoElement.addEventListener('play', handlePlay);
      videoElement.addEventListener('pause', handlePause);
    }

    // Clean up event listeners on component unmount
    return () => {
      if (videoElement) {
        videoElement.removeEventListener('play', handlePlay);
        videoElement.removeEventListener('pause', handlePause);
      }
      noSleep.current.disable(); // Ensure NoSleep is disabled when component unmounts
    };
  }, [currentAdIndex]);

  // const {isLoading, error, data, getData} = useVisitorData(
  //   {extendedResult: true},
  //   {immediate: true}
  // )

  // useEffect(()=>{
  //   // console.log("newdata",data);
  //   console.log("finger",myfingerprint);
  //   if(data?.visitorId){
  //   localStorage.setItem("finger",myfingerprint);}
  //   if(data){
  //   setmyFingerPrint(data?.visitorId)}
  // },[data])



  useEffect(() => {
    if (myFingerPrint) {
      const socket = io(`https://chat.trillboards.com?fingerprint=${myFingerPrint}`);
      socket.on('connect', () => {
        console.log('WebSocket connected', socket.id);
      })
      socket.on('privateMessage', (data) => {
        console.log("message from socket", data);
        if (JSON.parse(data)?.fetchAdd === true) {
          // window.location.reload();
          console.log("hello ");
          setisData(isData => !isData);
        }
      });
      return () => {
        socket.disconnect();
      };
    }
  }, [myFingerPrint])
  // sourabhverma

  function convertTimeTo12HourFormat() {
    const date = new Date();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12; // Convert hour to 12-hour format

    const timeString = `${formattedHours}:${String(minutes).padStart(2, '0')} ${ampm}`;
    myTimeRef.current = formattedHours
    setmyTime(timeString);
    // return finalFormattedTime;
  }

  // useEffect(()=>{
  //   const now = new Date();
  //     const timeString = `${now.getHours().toString().length === 1?'0'+now.getHours():now.getHours()}:${now.getMinutes().toString().length === 1?"0"+now.getMinutes():now.getMinutes()}`;
  //     console.log("timeString",timeString);
  //     console.log("adsdata",adsData?.current);
  //     // filterDataByTimeAndDate(timeString,adsData?.current);
  //     const nextHour = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours() + 1, 0, 0, 0);
  //     const delay = nextHour - now;
  //     setmyDelay(delay);
  // },[])

  useEffect(() => {
    console.log("myFingerPrint", myFingerPrint);
    const getdata = async () => {
      await axios.get(`${process.env.REACT_APP_APP_URL}v2/earner/alot-advertisement-list/${myId}`)
        .then((res) => {
          console.log('res screen connected', res);
          adsData.current = res?.data?.data;
          const now = new Date();
          const timeString = `${now.getHours().toString().length === 1 ? '0' + now.getHours() : now.getHours()}:${now.getMinutes().toString().length === 1 ? "0" + now.getMinutes() : now.getMinutes()}`;
          filterDataByTimeAndDate(timeString, res?.data?.data);
          convertTimeTo12HourFormat();
        })
        .catch((err) => { console.log('err screen connected', err) })
    }
    getdata();
  }, [myId, isData])


  useEffect(() => {
    console.log("ads", adsData.current);
  }, [adsData?.current])

  const [ads, setAds] = useState([
    { name: 'Ad1', start_time: '19:11', date: '2024-05-03', end_time: '19:30', imageUrl: 'xyz', videoUrl: 'xyz', mediaType: 'image' },
    { name: 'Ad2', start_time: '15:42', date: '2024-05-04', end_time: '18:13' },
    { name: 'Ad3', start_time: '16:40', date: '2024-05-03', end_time: '16:47' },
  ]);

  useEffect(() => {
    console.log("myTimeRef", myTimeRef);
    const now = new Date();
    const timeString = `${now.getHours().toString().length === 1 ? '0' + now.getHours() : now.getHours()}:${now.getMinutes().toString().length === 1 ? "0" + now.getMinutes() : now.getMinutes()}`;
    console.log("timeString", timeString);
    console.log("adsdata", adsData?.current);
    filterDataByTimeAndDate(timeString, adsData?.current);
  }, [myTimeRef.current])

  // useEffect(()=>{

  //   let ok=true;
  //   console.log("in interval one");
  //     const now = new Date();
  //     const timeString = `${now.getHours().toString().length === 1?'0'+now.getHours():now.getHours()}:${now.getMinutes().toString().length === 1?"0"+now.getMinutes():now.getMinutes()}`;
  //     console.log("timeString",timeString);
  //     console.log("adsdata",adsData?.current);
  //     filterDataByTimeAndDate(timeString,adsData?.current);
  //     const nextHour = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours() + 1, 0, 0, 0);
  //     const delay = nextHour - now;
  //     console.log("delay",delay/60000);

  //     // setmyDelay(()=>5000)

  //     const interval=setInterval(() => {
  //         console.log("in interval two");
  //         const now = new Date();
  //         const timeString = `${now.getHours().toString().length === 1?'0'+now.getHours():now.getHours()}:${now.getMinutes().toString().length === 1?"0"+now.getMinutes():now.getMinutes()}`;
  //         // filterDataByTimeAndDate(timeString,adsData?.current);
  //         // setmyDelay(()=>10000);
  //         ok=false;
  //       }, ok?5000:10000);
  //     return () => clearInterval(interval);
  // },[])

  // useEffect(() => {
  //   // let timeInterval=myfilteredData?.length === 0?35000:myfilteredData?.length*30000;
  // //   const now = new Date();
  // //     const timeString = `${now.getHours().toString().length === 1?'0'+now.getHours():now.getHours()}:${now.getMinutes().toString().length === 1?"0"+now.getMinutes():now.getMinutes()}`;
  // //     console.log("timeString",timeString);
  // //     console.log("adsdata",adsData?.current);
  // //   filterDataByTimeAndDate(timeString,adsData?.current);
  // // const nextHour = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours() + 1, 0, 0, 0);
  // // const delay = nextHour - now;

  // // let interval;

  // // setTimeout(()=>{
  // //   interval=setInterval(() => {
  // //     console.log("in");
  // //     const now = new Date();
  // //     const timeString = `${now.getHours().toString().length === 1?'0'+now.getHours():now.getHours()}:${now.getMinutes().toString().length === 1?"0"+now.getMinutes():now.getMinutes()}`;
  // //           filterDataByTimeAndDate(timeString,adsData?.current);
  // //   }, 60*60*1000);
  // // },delay)
  // const interval = setInterval(() => {
  //     console.log("in other interval",myfilteredData);
  //     const now = new Date();
  //     const timeString = `${now.getHours().toString().length === 1?'0'+now.getHours():now.getHours()}:${now.getMinutes().toString().length === 1?"0"+now.getMinutes():now.getMinutes()}`;
  //     console.log("timeString",timeString);
  //     console.log("adsdata",adsData?.current);
  //     filterDataByTimeAndDate(timeString,adsData?.current);
  //     convertTimeTo12HourFormat();
  //   }, 5000);
  //   return () => clearInterval(interval);

  // }, []);

  useEffect(() => {
    console.log("currentAdIndex", currentAdIndex);
  }, [currentAdIndex])

  useEffect(() => {
    const interval = setInterval(() => {
      convertTimeTo12HourFormat();
    }, 1000);
    return () => clearInterval(interval);
  }, [])

  // useEffect(() => {
  //   // Reset the current index when filteredAds change
  //   setCurrentAdIndex(0);
  // }, [myfilteredData]);


  useEffect(() => {
    if (myfilteredData.length > 0) {
      const intervalId = setInterval(() => {
        console.log("in first interval", myfilteredData);
        setCurrentAdIndex((prevIndex) => (prevIndex + 1) % myfilteredData.length);
      }, 30000); // 30 seconds

      return () => clearInterval(intervalId);
    }
  }, [myfilteredData]);


  const timeToMinutes = (time) => {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  };

  const isCurrentTimeInRange = (timeString, startTime, endTime) => {
    // Convert time strings to Date objects
    const time = new Date(`1970-01-01T${timeString}:00`);
    const start = new Date(`1970-01-01T${startTime}:00`);
    const end = new Date(`1970-01-01T${endTime}:00`);

    // Handle case where end time is technically on the next day
    if (end < start) {
      // Extend end time to the next day if it is less than start time
      end.setDate(end.getDate() + 1);
      if (time < start) {
        time.setDate(time.getDate() + 1);
      }
    }

    return time >= start && time < end;
  };

  // const filterDataByTimeRange = (timeString) => {
  //   const currentDate = new Date().toISOString().slice(0, 10);
  //   console.log("currentdate",currentDate); // Get today's date in "YYYY-MM-DD" format
  //   console.log("called");
  //   const filteredData = adsData.filter(item => {
  //     console.log("item date",item?.date,"----",currentDate);
  //     if (item?.date !== currentDate) {
  //       return false; // Skip if date doesn't match today's date
  //     }
  //     return isCurrentTimeInRange(timeString, item.start_time, item.end_time);
  //   });
  //   setmyfilteredData(filteredData)
  //   return filteredData;
  // };
  const filterDataByTimeAndDate = (timeString, myData) => {
    console.log("myData", myData);
    const currentDate = new Date().toLocaleDateString('en-CA');  // 'en-CA' gives the format YYYY-MM-DD
    console.log("currentDate", currentDate); // Get today's date in "YYYY-MM-DD" format
    console.log("called");

    let filteredArray = [];
    const filteredData = myData?.filter(item => {
      // Check if any slot's date matches today's date
      const dateMatches = item?.slots?.some(slot => {
        return slot.date === currentDate;
      });

      if (!dateMatches) {
        console.log("Skipping item due to date mismatch");
        return false; // Skip if no slot matches today's date
      }

      // Check if the timeString falls within any of the slots' time ranges
      return item?.slots?.some(slot => {
        return isCurrentTimeInRange(timeString, slot.start_time, slot.end_time);
      });
    });

    console.log("filteredData", filteredData);
    setmyfilteredDataLength(filteredData?.length);
    setmyfilteredData(filteredData);
    return filteredData;
  };


  useEffect(() => {
    console.log("my filtreed data", myfilteredData);
  }, [myfilteredData])

  // const data=[
  //     {
  //         name:"first Add",
  //         imageUrl:'https://images.unsplash.com/photo-1509721434272-b79147e0e708?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80'
  //     },
  //     {
  //         name:"first Add",
  //         imageUrl:'https://images.unsplash.com/photo-1509721434272-b79147e0e708?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80'
  //     },
  //     {
  //         name:"first Add",
  //         imageUrl:'https://images.unsplash.com/photo-1509721434272-b79147e0e708?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80'
  //     }
  // ]

  // useEffect(()=>{
  //     const allImages=data?.map((item,index)=>{return item?.imageUrl});
  //     setmyImages(allImages);
  //     console.log("allImages",myImages);
  //     // setTimeout(() => {
  //     //   setjcheck(!jcheck);
  //     //   console.log("jcheck",jcheck)
  //     // }, 5000);
  // },[])
  return (
    <>
      {(myfilteredData?.length > 0) ?
        <>
          {myfilteredData[currentAdIndex]?.mediaType === "image" ?
            <div className="slide-container">
              <Slide responsive={true}>
                {myfilteredData[currentAdIndex]?.image?.map((slideImage, index) => (
                  <div key={index} style={{ position: 'relative', height: '100vh', width: '100vw', overflow: 'hidden' }}>
                    <img
                      src={slideImage}
                      style={{
                        height: '100%',
                        width: '100%',
                        objectFit: 'contain'
                      }}
                      alt='slider_img'
                    />
                    {/* <div style={{ position: 'absolute', bottom: '10px', right: '10px' }}>
                      <QRCode
                        size={200}
                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                        value={`${process.env.REACT_APP_APP_URL}v1/user/scan-count?advertisementId=${myfilteredData[currentAdIndex]?.id}&userId=${myfilteredData[currentAdIndex]?.userId}`}
                        logoImage={trillBoardLogo}
                        logoPadding={4}
                        logoHeight={50}
                        logoWidth={50}
                      />
                    </div> */}
                    {myfilteredData[currentAdIndex]?.campaign_link !== "" && (
                      <div className="qrcode-container">
                      <QRCode
                        size={180}
                        style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                        value={`${process.env.REACT_APP_APP_URL}v1/user/scan-count?advertisementId=${myfilteredData[currentAdIndex]?.id}&userId=${myfilteredData[currentAdIndex]?.userId}`}
                        logoImage={trillBoardLogo}
                        logoPadding={4}
                        logoHeight={50}
                        logoWidth={50}
                      />
                    </div>
                    )}
                  </div>



                ))}
              </Slide>
            </div>
            :
            <div style={{ position: 'relative', height: '100vh', width: '100%', overflow: 'hidden' }}>
              <video
                ref={videoRef} // Attach the ref to the video element
                key={myfilteredData[currentAdIndex]?.video}
                height="100%"
                width="100%"
                style={{ objectFit: 'cover' }}
                controls
                autoPlay
                muted
                loop
                preload="true"
              >
                <source src={myfilteredData[currentAdIndex]?.video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              {myfilteredData[currentAdIndex]?.campaign_link !== "" && (
                <div className="qrcode-container">
                <QRCode
                  size={180}
                  style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                  value={`${process.env.REACT_APP_APP_URL}v1/user/scan-count?advertisementId=${myfilteredData[currentAdIndex]?.id}&userId=${myfilteredData[currentAdIndex]?.userId}`}
                  logoImage={trillBoardLogo}
                  logoPadding={4}
                  logoHeight={50}
                  logoWidth={50}
                />
              </div>
              )}
              {/* <div style={{ position: 'absolute', right: 80, bottom: 80, height: '200px', width: '200px' }}>
                <QRCode
                  size={200}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={`${process.env.REACT_APP_APP_URL}v1/user/scan-count?advertisementId=${myfilteredData[currentAdIndex]?.id}&userId=${myfilteredData[currentAdIndex]?.userId}`}
                  logoImage={trillBoardLogo}
                  logoPadding={4}
                  logoHeight={50}
                  logoWidth={50}
                />
              </div> */}
            </div>
          }
        </>
        : <> <section class="trillboard_wrap">
          <div class="blue_boxwrap">
            <div class="trillboard_image">
              <img src={trillBoardLogo} alt="" />
            </div>
          </div>
        </section>
          <section class="trillboard_scanboard " >
            <div class="scanorcode_wrap">
              <h2>Your Screen is successfully connected !</h2>
              <img class="connect_img" src={connectedImage} alt="" />
            </div>
            <div class="trillboard_advertisement">
              <div class="trillboard_text">
                <h2>There is no advertisement <br /> running currently</h2>
              </div>
              <div class="trillboard_gst">
                <h4>{myTime}</h4>
              </div>
            </div>
          </section></>}
    </>
  )
}

export default ScreenConnected;


