import React, { useEffect } from 'react';
import QRcode from './Components/QRCode';
import Dummy from './Components/Dummy';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

function App() {
  useEffect(() => {
    // Function to keep the screen awake
    function preventScreensaver() {
      let x = 0, y = 0; // Starting mouse position
      const interval = 5000; // Move every 5 seconds

      setInterval(() => {
          x += 1; // Increment x position
          y += 1; // Increment y position
          // Simulate mousemove event
          const event = new MouseEvent('mousemove', {
              view: window,
              bubbles: true,
              cancelable: true,
              clientX: x % window.innerWidth, // Wrap around screen width
              clientY: y % window.innerHeight // Wrap around screen height
          });
          document.dispatchEvent(event);
          console.log('Simulated mouse movement to prevent screensaver.');
      }, interval);
  }

    // Run the function every 10 seconds
    const intervalId = setInterval(() => {
      console.log('Executing preventScreensaver');
      preventScreensaver();
    }, 10000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<QRcode />} />
        <Route path='/dummy' element={<Dummy />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
